import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { ProgramListTemplate } from '../templates/program-list.template';
import SEO from '../components/seo';
import { ProgramListQuery } from '../../graphql-types';
import { parseLocale } from '../locale';
import { mapProgramListByCategoryQueryToProgramListProps } from '../data-mappers/program-list.mapper';
import { TranslationData } from '../pages/newsroom';

export const query = graphql`
  query ProgramListByCategory($locale: String, $id: ID!) {
    cms {
      programList(locale: $locale) {
        ...ProgramListContent
      }
      categories(locale: $locale, sort: "created_at:desc") {
        ...ProgramCategory
        programCount
      }
      category(id: $id) {
        programs(sort: "updated_at:desc") {
          ...ProgramWithThumbnail
        }
      }
    }
  }
`;

type ProgramPageProps = {
  data: ProgramListQuery;
  pageContext: {
    id: string;
  };
};

const ProgramListPage: React.FC<ProgramPageProps> = ({ pageContext, data }) => {
  const { cms } = data;
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  if (!cms?.programList) return null;

  const translation: TranslationData = {
    locale: locale,
    load_more: intl.formatMessage({ id: 'load_more' }),
    read_more: intl.formatMessage({ id: 'read_more' }),
  };

  const programListProps = mapProgramListByCategoryQueryToProgramListProps(cms, translation, pageContext.id);

  return (
    <>
      <SEO
        lang={locale}
        title={intl.formatMessage({ id: 'programs' })}
        description={cms.programList.metas?.description ?? ''}
      />
      {programListProps && <ProgramListTemplate {...programListProps} />}
    </>
  );
};

export default ProgramListPage;
